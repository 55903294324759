import React, { useEffect, useState } from "react";
import "./styles/index.css";
import Header from "./Header";
import ListMenu from "./ListMenu";
import icons from "../assets";
import Dashboard from "./Dashboard";
import { Navigate, Route, Routes } from "react-router-dom";
import Orders from "./Orders";

const Index: React.FC<{}> = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Add isLoggedIn state
  const [isAdmin, setIsAdmin] = useState(false); // Add isAdmin state
  const [isLoaded, setIsLoaded] = useState(false);

  const rediractePage = (link: string) => {
    window.location.href = link;
    return null;
  };

  useEffect(() => {
    // Make a request to /auth/check to determine if the user is logged in
    fetch("https://api.ybs-closet.com/auth/check", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        else throw new Error("User is not logged in");
      })
      .then((data) => {
        setIsLoggedIn(true);
        setIsLoaded(true);
        setIsAdmin(data.isAdmin);
      })
      .catch((error) => {
        console.error("Error checking login status:", error);
        setIsLoggedIn(false);
        setIsLoaded(true);
        setIsAdmin(false);
      });
  }, []);

  const menuLinks = [
    { name: "Dashboard", iconSVG: icons.dashboard, link: "/admin/dashboard" },
    { name: "Orders", iconSVG: icons.orders, link: "/admin/orders" },
    // { name: "Statistics", iconSVG: icons.statistics, link: "/admin/stats" },
  ];

  const logOut = () => {
    fetch("https://api.ybs-closet.com/auth/logout", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setIsLoggedIn(false);
          setIsAdmin(false);
          setIsLoaded(true);
        } else throw new Error("Error logging out");
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  return (
    <div className="admin-panel">
      {isLoaded ? (
        isLoggedIn ? ( // Render the content only if the user is logged in
          isAdmin ? (
            <>
              <div className="side-menu">
                <Header title="Admin Panel" links={menuLinks} logOut={logOut} />
                <ListMenu links={menuLinks} />
              </div>
              <Routes>
                <Route path="/" element={<Navigate to="/admin/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/stats" element={null} />
                <Route path="/*" element={<Navigate to="/admin/dashboard" />} />
              </Routes>
              <button className="log-out-btn" onClick={logOut}>
                <img
                  src={icons.logOut}
                  alt="Log Out"
                  className="log-out-icon"
                />
                Log Out
              </button>
            </>
          ) : (
            <div className="access-denied">
              <h1>Access Denied</h1>
              <p>You do not have permission to view this page.</p>
            </div>
          )
        ) : (
          rediractePage("https://api.ybs-closet.com/auth/google")
        )
      ) : null}
    </div>
  );
};

export default Index;
